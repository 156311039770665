<template>
    <div class="chat">
        <div class="chat-header clearfix">
            <div class="row">
                <div class="col-lg-6">
                    <a href="javascript:void(0);" data-toggle="modal" data-target="#view_info">
                        <img  :srcset="assets('morelogo_nd.png')" alt="avatar">
                    </a>
                    <div class="chat-about">
                        <h6 class="m-b-0">MORE</h6>
                        <!-- <small>Last seen: 2 hours ago</small> -->
                    </div>
                </div>
                <div class="col-lg-6 hidden-sm text-right" style="display: none;">
                    <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="fa fa-camera"></i></a>
                    
                    <input type="file" id="upload-photo">
                    <a for="upload-photo" href="javascript:void(0);" class="btn btn-outline-primary dz-message" data-dz-message>
                        
                        <i class="fa fa-image"></i></a>
                    
                    <!-- <a href="javascript:void(0);" class="btn btn-outline-info"><i class="fa fa-cogs"></i></a> -->
                    <a href="javascript:void(0);" class="btn btn-outline-warning"><i class="fa fa-question"></i></a>
                </div>
            </div>
        </div>
        <form  id="chat-history" class="chat-history" ref="list" @scroll="initScroll">
            <ul class="m-b-0" id="m-b-0" >
                <div v-if="loading_more" style="text-align: center;" >
                    <img :src="require('../../assets/loading.gif')" width="40px" />
                </div>
                <template v-for="item in data.slice().reverse()"  >
                    <template v-if="item.r_uni_products">
                        <li :key="item.id" class="clearfix">
                            <div v-if="!item.admin_users_id" class="message-data text-right">
                                <span class="message-data-time">{{item.created_at}}</span>
                                <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="avatar">
                            </div>
                            <div v-else class="message-data">
                                <span class="message-data-time">{{item.created_at}}</span>
                            </div>
                            <div v-if="!item.admin_users_id" class="message other-message float-right"> {{ item.text }}</div>
                            <div v-else class="message my-message">{{ item.text }}</div>
                        </li>
                        <li :key="item.id+'_p'" class="clearfix">
                            <div class="message-data text-right">
                            </div>
                            <div class="message other-message float-right proddetail">
                                <img :srcset="item.r_uni_products.r_uni_product_images[0].image_url">
                                <div>
                                    <h3 class="ol-home-product__content-item-list--item-title">
                                        <router-link :to="'/product/'+item.r_uni_products.slug">
                                            {{item.r_uni_products.name}}
                                        </router-link>
                                    </h3>
                                    <div class="ol-home-product__content-item-list--item-price">
                                        <span v-if="item.r_uni_products.discount_max && item.r_uni_products.discount_max != '0%' && item.r_uni_products.price_normal != 'Rp0' " class="ol-home-product__content-item-list--item-price-real">{{item.r_uni_products.price_normal}}</span>
                                        <small class="ol-home-product__content-item-list--item-price-discount">{{item.r_uni_products.price_range}}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </template>
                    <template v-else-if="item.file">
                        <li v-if="item.text" :key="item.id" class="clearfix">
                            <div v-if="!item.admin_users_id" class="message-data text-right">
                                <span class="message-data-time">{{item.created_at}}</span>
                                <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="avatar">
                            </div>
                            <div v-else class="message-data">
                                <span class="message-data-time">{{item.created_at}}</span>
                            </div>
                            <div v-if="!item.admin_users_id" class="message other-message float-right"> {{ item.text }}</div>
                            <div v-else class="message my-message">{{ item.text }}</div>
                        </li>
                        <li :key="item.id+'_g'" class="clearfix">
                            <div v-if="!item.admin_users_id" class="message-data text-right">
                            </div>
                            <div v-else class="message-data">
                            </div>
                            <div v-if="!item.admin_users_id" class="message other-message float-right imgsend">
                                <a :href="item.file" target="_blank">
                                    <img :srcset="item.file">
                                </a>
                                
                            </div>
                            <div v-else class="message other-message imgsend">
                                <a :href="item.file" target="_blank">
                                    <img :srcset="item.file">
                                </a>
                            </div>
                        </li>
                        
                    </template>
                    <template v-else>
                        <li :key="item.id" class="clearfix">
                            <div v-if="!item.admin_users_id" class="message-data text-right">
                                <span class="message-data-time">{{item.created_at}}</span>
                                <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="avatar">
                            </div>
                            <div v-else class="message-data">
                                <span class="message-data-time">{{item.created_at}}</span>
                            </div>
                            <div v-if="!item.admin_users_id" class="message other-message float-right"> {{ item.text }}</div>
                            <div v-else class="message my-message">{{ item.text }}</div>
                        </li>
                    </template>
                    
                </template>
                    
                
                
                
            </ul>
        </form>
        <div class="chat-message clearfix">
            <div class="input-group mb-0">
                <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-paper-plane"></i></span>
                </div>
                <input type="text" class="form-control" placeholder="Enter text here..." v-on:keyup.enter="onEnter" v-model="msg">                                    
            </div>
        </div>
    </div>
</template>
<script>
import globalUrl from '@/util/globallUrl'
import { mapGetters, mapActions } from "vuex";
const URL = globalUrl.WS_CHATS;
export default {
    name:"chats",
    data(){
        return {
            loading: true,
            loading_more:false,
            load_more:false,
            history_scrollTop:0,
            data:[],
            page:1,
            per_page:10,
            msg: '',
            readQueued:null,

        }
    },
    updated() {
        // whenever data changes and the component re-renders, this is called.
        this.$nextTick(() => {if(this.page == 1) this.scrollToEnd()});
        this.queueRead();
    },
    methods:{
        ...mapActions("chats", ["fetchData",'saveChat','read']),
        jqScrolling2top(){
            $("a#a-footer-to-top").trigger("click");
        },
        scrollToEnd: function () {
            // scroll to the start of the last message
            var container = this.$el.querySelector("#m-b-0");
            // console.log(container.scrollHeight);

            this.$refs.list.scrollTop =   container.scrollHeight;
        },
        initScroll(){
            var vm = this;
            if($(".chat-history").scrollTop() ===0) {
                var container = this.$el.querySelector("#m-b-0");
                this.history_scrollTop = container.scrollHeight;
                // console.log(vm.load_more); 
                if(vm.load_more){
                    vm.page++;
                    vm.loading_more =true;
                    vm.getData();
                }
                
            }
        },
        onEnter: function() {
            this.scrollToEnd();
            // console.log(this.msg);
            let pesan = this.msg;
            this.msg = '';
            if( pesan != ''){
                this.saveChat({text:pesan}).then(result =>{
                    let response = result.data;
                    this.data = [...[response],...this.data];
                    this.scrollToEnd();

                }).catch(error =>{
                    // console.log(error);
                });
            }
        },
        async getData(){
            this.loading=true;
            this.jqScrolling2top();
            let data={
                page: this.page,
                per_page:this.per_page
            };
            this.fetchData(data).then((result) =>{
                this.loading = false;
                this.loading_more = false;
                let response = result.data;
                this.load_more = response.next_page_url ? true: false;
                this.data = [...this.data,...response.data];
                // console.log(this.data);
                if(this.page != 1){
                    var container = this.$el.querySelector("#m-b-0");
                    this.$refs.list.scrollTop = container.scrollHeight - this.history_scrollTop;
                }
                
            }).catch((error)=>{
                this.loading = false;
                // console.log(error);
            });
        },
        queueRead(usingQueue = true){
            if(this.readQueued != null) clearTimeout(this.readQueued);
            if(usingQueue){
                this.readQueued = setTimeout(async () => {
                    this.read();
                }, 500);
            }else{
                this.read();
            }
        },
    },
    async mounted(){
        //  this.jqScrolling2top();
        this.getData();
    }
}
</script>
<style scoped>
    @import '../../assets/css/bootstrap.css';
    @import '../../assets/css/chat.css';
    body{
        overflow-y: hidden;
    }
</style>
